import { PolyglotOptions } from 'node-polyglot';

const ADDRESS_ATTRIBUTES = {
  postal_code: 'Postal Code',
  address_level: 'Prefecture, City, Town',
  state: 'Prefecture',
  city: 'City',
  city_kana: 'City (kana)',
  town: 'Town',
  town_kana: 'Town (kana)',
  line1: 'Building and Street Address',
  line1_kana: 'Building and Street Address (kana)',
  line2: 'Building Name / Room No.',
  line2_kana: 'Building Name / Room No. (kana)',
};

const phrases = {
  nagaku: 'NAGAKU',

  /**
   * MARK: Title
   * For <title> tag and the lergest heading within each page.
   */
  'title.home': 'Professional Repair and Remake Service with Portfolio Search',
  'title.login_to_nagaku': 'Log in to NAGAKU',
  'title.signup_to_nagaku': 'Join NAGAKU today',
  'title.feed': 'Feed',
  'title.tags': 'Tag List',
  'title.explore': 'Search by Tag',
  'title.contact': 'Contact US',
  'title.new_work': 'New Post',
  'title.edit_work': 'Edit your Post',
  'title.draft_work': 'Draft',
  'title.likes': 'Liked Posts',
  'title.work': 'Repair by {{display_name}}',
  'title.messages': 'Messages',
  'title.reset_password': 'Reset Password',
  'title.latest_announcement': 'Latest news',
  'title.setting': 'Settings',
  'title.follows': 'Follows',
  'title.followers': 'Followers',
  'title.account_setting': 'Account',
  'title.addresses_setting': 'Address Management',
  'title.business_application_information': 'Submitted Business Information',
  'title.business_profile': 'Business Information',
  'title.reception': 'Edit Request Page',
  'title.email_verification': 'Verify Email Address',
  'title.jp_act_on_specified_commercial_transaction': 'Japanese Act On Specified Commercial Transaction',
  'title.jp_act_on_specified_commercial_transaction_edit': 'Edit Japanese Act On Specified Commercial Transaction',
  'title.pro_account_application': 'Pro Account Application',
  'title.privacy_policy': 'Privacy Policy',
  'title.terms_of_service': 'Terms of Service',
  'title.announcements': 'Latest News',
  'title.order': 'Order',
  'title.order_history': 'Order History',
  'title.tag': '#{{typeface}} - Recommended Pro Account services, repair and remake posts, and consultations',
  'title.profile_edit_page': 'Edit Profile',
  'title.japanese_act_on_specified_transactions': 'Specific Commercial Transactions Act',
  'title.draft_works': 'Draft',
  'title.inquiry': 'Inquiry',
  'title.quotation': 'Quotation / Invoice',
  'title.quotation_payment': 'Payment for your order',
  'title.dashboard': {
    orders: 'Orders',
  },
  'title.orders': 'Orders',
  'title.auth_failure': 'Authentication Failure',
  // for Pro Account Consultation
  'title.ask': 'Consult on Your Request',

  /**
   * MARK: Description
   * For <meta name="description"> tag and similar tags.
   */
  'description.prefix': 'Web Consultation & Estimates: ',
  'description.short':
    'Find and commission skilled artisans through our repair and remodeling service. Connect with professionals nationwide specializing in Kintsugi, Jewelry, Kimono, Furniture, Knife sharpening, Sneakers, Leather goods, and more. Easily consult through messaging and manage quotes, payments, and order completion online.',
  'description.explore':
    'Search for Pro Users and repair cases by Tag. Easily consult through messaging and manage quotes, payments, and order completion online. Kintsugi, Jewelry, Kimono, Furniture, Knife sharpening, Sneakers, Leather goods, etc.',
  'description.tags':
    'List of tags registered in NAGAKU. Easily consult through messaging and manage quotes, payments, and order completion online. Kintsugi, Jewelry, Kimono, Furniture, Knife sharpening, Sneakers, Leather goods, etc.',
  'description.tag':
    'Find numerous professionals and case studies on {{typeface}} repair and remodeling. Easily consult through messaging and manage quotes, payments, and order completion online.',
  'description.own_ask_page': 'This is your consultation page.',
  'description.ask_page_not_accept_repair_request': 'Currently, new repair requests are not accepted.',
  'description.auth_failure': 'Authentication failed. Please try again from the beginning.',

  /**
   * MARK: Label
   * Simple nouns? Requires refactoring.
   */
  'label.about_consultation_and_requests': 'About Consultation and Requests',
  'label.accounts': 'Users',
  'label.business': 'Business',
  'label.cancellation_policy': 'Cancellation Policy',
  'label.tags': 'Tag List',
  'label.filter_by_pro': 'Pro Only',
  'label.caller': 'Requester',
  'label.estimate': 'Estimate',
  'label.draft_estimate': 'Draft Estimate',
  'label.display_name': 'Username',
  'label.email': 'Email Address',
  'label.general_ticket_steps': 'Standard Process',
  'label.privacy_policy': 'Privacy Policy',
  'label.japanese_act_on_specified_transactions': 'Japanese Act on Specified Commercial Transactions',
  'label.help_and_contact_us': 'Help / Contact Us',
  'label.like': 'Like',
  'label.menu': 'Menu',
  'label.menu.home': 'Home',
  'label.menu.messages': 'Message Box',
  'label.menu.search_by_tag': 'Search by Tag',
  'label.menu.likes': 'Liked Posts',
  'label.menu.profile': 'My Profile',
  'label.menu.recommend_login': 'Login to NAGAKU and find Pro Accounts for repairs and remakes.',
  'label.menu.settings': 'Setting',
  'label.menu.order_history': 'Order History',
  'label.menu.dashboard.orders': 'Orders',
  'label.or': 'or',
  'label.other': 'Other',
  'label.page_hierarchy': 'Page Hierarchy',
  'label.pagination': 'Pagination',
  'label.password': 'Password',
  'label.primary_address': 'Primary',
  'label.pro': 'Pro',
  'label.pro_accounts': 'Pro Accounts',
  'label.notes_on_requests_and_consultations': 'Notes on Requests and Consultations',
  'label.about_order': 'About Order',
  'label.please_login': 'Please login',
  'label.please_verify_email': 'Please verify your email',
  'label.quotation': 'Quotation',
  'label.quotation.caller': 'Requester',
  'label.quotation.repairer': 'Repairer',
  'label.quotation.note': 'Notes',
  'label.quotation.overview': 'Repair Overview',
  'label.quotation.properties': 'Document Information',
  'label.quotation.items': 'Payment Items',
  'label.quotation.accounts': 'Requester / Repairer',
  'label.quotation.billing_address': 'Billing Address',
  'label.quotation.jp_trn_number': 'Registration Number',
  'label.draft_quotation': 'Draft Quotation',
  'label.remove': 'Remove',
  'label.repairer': 'Repairer',
  'label.setting_items': {
    account_setting: 'Account',
    addresses_setting: 'Address Management',
    reception: `Edit Request Page`,
    business_application: 'Pro Account Application',
    business_profile: 'Business Information',
    business_application_information: 'Submitted Business Information',
    jp_act_on_spacified_commercial_transaction: 'Japanese Act On Specified Commercial Transaction',
    profile_edit_page: 'Edit Profile',
  },
  'label.jp_act_on_specified_commercial_transaction': {
    hidden_address: 'address',
    hidden_phone: 'phone number',
  },
  'label.business_profile.address': 'Address',
  'label.status': 'Status',
  'label.ticket_participants': 'Participants',
  'label.ticket_steps': {
    consultation: 'Consultation',
    quotation: 'Quotation',
    payment: 'Payment',
    processing: 'Processing',
    delivery: 'Delivery & Completion',
  },
  'label.unverified_email': 'Unverified email address',
  'label.upload_image': 'Upload Image',
  'label.verified': 'Verified',
  'label.verification_document': {
    individual_number_card: 'My Number Card, with photo',
    drivers_license: "Driver's license",
    residence_card: 'Residence Card',
    japanese_passport: 'Japanese passport',
    residence_certificate: 'Residence Certificate',
  },
  'label.verification_document_side': {
    front: 'Front Side',
    back: 'Back Side',
  },
  'label.verification_document_status': {
    uploaded: 'Upload Complete',
    pending: 'Yet to Upload',
  },
  'label.work': 'Post',
  'label.works': 'Posts',
  'label.see_information': 'See info',

  'alt.photo_by': 'Photo by {{display_name}}',

  // Requires replacing.
  name: {
    individual_number_card: 'Individual Number Card, nicknamed "My Number Card" in Japan',
    individual_number_card_front: 'Individual Number Card (Front)',
    individual_number_card_back: 'Individual Number Card (Back)',
    drivers_license: "Driver's License",
    drivers_license_front: "Driver's License (Front)",
    drivers_license_back: "Driver's License (Back)",
  },

  /**
   * MARK: Attribute
   * Names of attributes within each model.
   * These phrases are used in any Field components.
   */
  attributes: {
    work: {
      before_image_media_file: 'Before Image',
      after_image_media_file: 'After Image',
      image_media_files: 'Additional Images',
      body: 'Post Body',
      tags: 'Tag',
      estimate_amount: 'Amount',
      estimate_delivery_days: 'Delivery Days',
    },
    account: {
      display_name: 'Display Name',
      screen_name: 'Account ID',
      tagline: 'Tagline (Service Overview)',
      email: 'Email Address',
      new_email: 'New Email Address',
      current_email: 'Current Email Address',
      bio: 'Profile',
      current_password: 'Current Password',
      password: 'Password',
      confirmation_password: 'Password (Confirm)',
      new_password: 'New Password',
      confirmation_new_password: 'New Password (Confirm)',
    },
    address: {
      name: 'Name',
      name_kana: 'Name (kana)',
      phone: 'Phone Number',
      address: 'Address',
      ...ADDRESS_ATTRIBUTES,
    },
    business: {
      business_type: 'Type of Business',
      jp_trn_number: 'Japan Invoice Registration Number',
      verification_document_type: 'Document Type',
      profile: {
        description: 'Product description',
      },
      company: {
        name: 'Legal business name (alphabetical)',
        name_kanji: 'Legal business name (kanji)',
        name_kana: 'Legal business name (kana)',
        phone: 'Business phone number',
        tax_id: 'Corporate Number (Japan)',
        email: 'Email Address',
        ...ADDRESS_ATTRIBUTES,
      },
      individual: {
        last_name: 'Last Name',
        last_name_kana: 'Last Name (kana)',
        first_name: 'First Name',
        first_name_kana: 'First Name (kana)',
        dob: 'Date of birth',
        phone: 'Phone Number',
        email: 'Email Address',
        ...ADDRESS_ATTRIBUTES,
      },
      representative: {
        last_name: 'Family Name',
        last_name_kana: 'Family Name (kana)',
        first_name: 'Given Name',
        first_name_kana: 'Given Name (kana)',
        dob: 'Date of birth',
        title: 'Job title',
        phone: 'Phone Number',
        email: 'Email Address',
        ...ADDRESS_ATTRIBUTES,
      },
      director: {
        last_name: 'Last Name',
        last_name_kana: 'Last Name (kana)',
        first_name: 'First Name',
        first_name_kana: 'First Name (kana)',
        dob: 'Date of birth',
      },
      bank_account: {
        account_holder_name: 'Account Holder Name',
        bank_name: 'Bank Name',
        bank_number: 'Bank Number',
        branch_number: 'Branch Number',
        account_number: 'Account Number',
      },
    },
    payment: {
      payment_date: 'Payment Date',
      total_amount: 'Total Amount',
    },
    ticket: {
      procedure: 'Requested Procedure',
      repair_request: {
        title: 'Subject',
        body: 'Body',
        photo_media_files: 'Photos for Repair',
      },
      participants: {
        role: {
          repairer: '受注者',
          caller: '依頼者',
        },
      },
    },
    ticket_comment: {
      body: 'Body',
    },
    message: {
      status: {
        all: 'All',
        consultation: 'Consultation',
        finalized: 'Orderd',
        completed: 'Completed',
      },
    },
    estimate: {
      title: 'Title',
      published_at: 'Published Date',
      published_date: 'Published Date',
      expires_date: 'Expires Date',
      note: 'Note',
      add_item: 'Add Item',
      subtotal_amount: 'Sub Total',
      consumption_tax_amount: 'Consumption Tax',
      total_amount: 'Total Amount',
      application_fee_base: 'Platform Fee Base',
      application_fee: 'Platform Fee (20%)',
      revenue: 'Revenue',
      estimate_not_included: 'This information is not included in the official estimate.',
      items: {
        label: 'Item Name',
        amount: 'Amount',
        ex_tax: 'ex.tax',
        quantity: 'Quantity',
        sum: 'Sum',
      },
      shipping_fee: 'Shipping Fee',
      estimate_delivery_days: 'Delivery Days',
    },
    quotation: {
      title: 'Title',
      published_at: 'Published Date',
      published_date: 'Published Date',
      expires_date: 'Expires Date',
      management_number: 'Management Number',
      note: 'Note',
      add_item: 'Add Item',
      subtotal_amount: 'Sub Total',
      consumption_tax_amount: 'Consumption Tax',
      total_amount: 'Total Amount',
      application_fee_base: 'Platform Fee Base',
      application_fee: 'Platform Fee (20%)',
      revenue: 'Revenue',
      estimate_not_included: 'This information is not included in the official estimate.',
      items: {
        label: 'Item Name',
        amount: 'Amount',
        ex_tax: 'ex.tax',
        quantity: 'Quantity',
        sum: 'Sum',
      },
      shipping_fee: 'Shipping Fee',
      estimate_delivery_days: 'Delivery Days',
    },
    order: {
      timestamp: 'Timestamp',
      amount: 'Amount',
    },
    business_profile: {
      description: 'Description',
      estimated_delivery: 'Estimated delivery',
      support_types: 'Support Order Types',
      shipping_method: 'Shipping Method',
      support_shipping: 'Shipping',
      support_in_store_drop_off: 'In store drop off',
      support_on_site_pickup: 'On site pickup',
      support_on_site_repair: 'On site repair',
      accept_repair_request: 'Accept new repair request',
      address_hidden: 'Address Publication Settings',
      support_areas: 'Support Areas',
      business_hours: 'Business Hours',
      regular_closed_days: 'Regular Closed Days',
      notes: 'Notes',
      cancellation_policy: 'Cancellation Policy',
      reception_message: 'Short Message',
      reception_body_annotation: 'Note for Consultation Body',
      ...ADDRESS_ATTRIBUTES,
    },
    jp_act_on_specified_commercial_transaction: {
      corporate_name: 'Corporate Name',
      representative_name: "Representative's Name",
      address: 'Address',
      address_hidden: 'Hide Address',
      phone: 'Phone Number',
      phone_hidden: 'Hide Phone Number',
      business_hours_and_closed_days: 'Business Hours and Closed Days',
      pricing: 'Pricing for Main Services',
      additional_fees: 'Additional Fees',
      payment_method: 'Payment Methods',
      payment_timing: 'Payment Timing',
      estimated_delivery: 'Estimated Delivery',
      cancellation_policy: 'Cancellation and Return Policy',
      after_service_policy: 'After-Sales Policy',
    },
    inquiry: {
      inquirer_name: 'Name',
      phone: 'Phone Number',
      email: 'Email Address',
      message_id: 'Message ID',
      subject: 'Subject',
      body: 'Body',
    },
    bank_account: {
      account_holder_name: 'Account Holder Name',
      bank_name: 'Bank Name',
      bank_number: 'Bank Number',
      branch_number: 'Branch Number',
      account_number: 'Account Number',
    },
    confirmation: 'Agreement to Terms of Service and Privacy Policy',
  },

  /**
   * MARK: Annotation
   * Holds additional descriptive notes for attributes.
   */
  annotations: {
    account: {
      screen_name: 'An Account ID composed of only common words may be subject to suspension without prior notice.',
      bio: 'Line breaks entered will not be reflected.',
    },
    business: {
      bank_account: {
        account_number:
          'If you are using Japan Post Bank, please add zeros to the beginning of your account number to make it 7 digits.',
        bank_name:
          'The account holder name must match the individual or legal entity name entered in this application.',
      },
    },
    business_profile: {
      address_hidden:
        'Even if you opt to hide your detailed address, your city, town, or district will still be visible and used for search purposes. Your full address will only be shared when necessary for shipping.',
    },
    quotation: {
      expires_date: '7 days after today to 180 days after today',
      shipping_fee:
        "Enter the shipping fee if you want to charge the actual shipping fee. The shipping fee does not include NAGAKU's fee.",
    },
    reception: {
      cancellation_policy: 'The cancellation policy can be edited in {{breadcrumb}}.',
    },
    ticket: {
      repair_request: {
        photo_media_files:
          'You may upload up to 8 photos. Please take a close-up of the area to be repaired, or a picture of the entire area.',
      },
      about_consultation_and_requests:
        'There are no costs until you receive a confirmed estimate and complete payment after consultation.',
    },
    jp_act_on_specified_commercial_transaction: {
      address_hidden:
        'If you choose to keep your address private, our address will be displayed instead. In case of inquiries from customers, you may be asked to disclose your address if necessary.',
      phone_hidden:
        'If you choose to keep your phone number private, our number will be displayed instead. In case of inquiries from customers, you may be asked to disclose your number if necessary.',
      after_service_policy: 'e.g., re-repair after delivery',
    },
    work: {
      tags: 'You must add at least one tag. You can add up to 5 tags.',
    },
  },

  /**
   * MARK: Validation Guides
   * {please write a simple comment}
   */
  validation_guides: {
    account: {
      display_name: 'Maximum 32 characters',
      password: 'At least 8 alphabetic, numeric and symbolic characters',
      new_password: 'At least 8 alphabetic, numeric and symbolic characters',
      screen_name: "3 - 32 lower case alphabetic, numeric or '-', '_', '+', '.' characters",
      tagline: 'Maximum 32 characters',
    },
  },

  /**
   * MARK: Placeholder
   */

  placeholder: {
    email: 'nagaku@example.com',
    password: 'Enter your password',
    phone: '000-0000-0000',
    tags: 'Search for repair and remake professionals and examples',
    search_by_tag: 'Search by Tag',
    account: {
      display_name: 'Name displayed to other users',
      screen_name: 'your-account-id',
      email: 'nagaku@example.com',
      password: 'Enter your password',
      current_password: 'Please input',
      confirmation_password: 'Please input again',
      tagline: 'Leather Goods Repair and Sneaker Remake',
      bio: 'Please input',
    },
    address: {
      name: '長久太郎',
      name_kana: 'ナガクタロウ',
      phone: '000-0000-0000',
      postal_code: '000-0000',
      state: 'Prefecture',
      city: 'City/Ward',
      town: 'Town/cho-me',
      line1: 'Block/Building number',
      line2: 'Building details',
    },
    ticket: {
      repair_request: {
        body: 'How you want it fixed, desired budget, delivery date, etc.',
      },
    },
    work: {
      body: 'Details of ingenuity, highlights to note, and the story of production.',
      estimate_amount: '15,000',
      estimate_delivery_days: '4',
    },
    ticket_comment: {
      body: 'Message...',
    },
    business: {
      description:
        'We have a workshop in Shibuya, Tokyo, where we repair and restore vintage furniture, refinish paint, resize, and offer customizations. The delivery time is approximately two months.',
      jp_trn_number: 'T0000000000000',
      company: {
        name_kanji: 'ナガク株式会社',
        name_kana: 'ナガク（カ',
      },
      bank_account: {
        account_holder_name: 'ナガクタロウ',
        bank_name: 'Search banks by hiragana, katakana, or romaji',
        bank_number: '0000',
        branch_number: '000',
        account_number: '1234567',
      },
    },
    address_kana: {
      postal_code: '000-0000',
      state: 'Prefecture (kana)',
      city: 'City/Ward (kana)',
      town: 'Town/cho-me (kana)',
      line1: 'Block/Building number (kana)',
      line2: 'Building details (kana)',
    },
    business_profile: {
      description: 'Please input',
      estimated_delivery: '',
      shipping_method: '',
      support_areas: '',
      business_hours: '9:00~18:00',
      regular_closed_days: '',
      notes: 'Please input',
      cancellation_policy: 'Please input',
      reception_message_legend: 'Entered text will be displayed here',
      reception_body_annotation_legend: 'Entered text will be displayed here',
      reception_message: 'Please input',
      reception_body_annotation: 'Please input',
    },
    estimate: {
      title: 'Chair Repair Estimate - {{display_name}}',
      items: {
        label: 'Repair Fee',
        amount: '24,000',
      },
      shipping_fee: '1,000',
      estimate_delivery_days: '1',
    },
    quotation: {
      title: 'Chair Repair Quotation - {{display_name}}',
      items: {
        label: 'Repair Fee',
        amount: '24,000',
      },
      shipping_fee: '1,000',
      estimate_delivery_days: '1',
    },
    jp_act_on_specified_commercial_transaction: {
      corporate_name: 'NAGAKU inc.',
      representative_name: 'Taro Nagaku',
      address: '692-2 Habashita, Minami-Nagano, Nagano City, Nagano Prefecture, Japan',
      phone: '000-0000-0000',
      business_hours_and_closed_days: 'Please input',
      pricing: 'Please input',
      additional_fees: 'Please input',
      estimated_delivery: 'Please input',
      cancellation_policy: 'Please input',
      after_service_policy: 'Please input',
    },
  },

  /**
   * MARK: action
   * the labels for buttons and other interaction elements.
   */
  'action.back': 'Back',
  'action.login': 'Login',
  'action.signup': 'Signup',
  'action.signup_or_login': 'Signup / Login',
  'action.continue_with_google': 'Continue with Google',
  'action.logout': 'Logout',
  'action.create_account': 'Create Account',
  'action.search_by_tag': 'Search by Tag',
  'action.see_more_tag': 'See more Tag',
  'action.post': 'Post',
  'action.send': 'Send',
  'action.verify': 'Verify',
  'action.verify_email': 'Verify Email',
  'action.edit': 'Edit',
  'action.edit_input': 'Edit Input',
  'action.modify_input': 'Modify Input',
  'action.copy_work_url': 'Copy post URL',
  'action.review_input': 'Review Input',
  'action.next': 'Next',
  'action.check_account_setting': 'Check',
  'action.save_as_draft': 'Save as Draft',
  'action.close': 'Close',
  'action.save': 'Save',
  'action.close_without_saving': 'Close without Saving',
  'action.delete': 'Delete',
  'action.update': 'Update',
  'action.attach': 'Attach',
  'action.attach_file': 'Image',
  'action.attach_estimates': 'Estimates',
  'action.attach_quotation': 'Quotation / Invoice',
  'action.attach_estimate': 'Estimate',
  'action.complete_edit': 'Complete Edit',
  'action.reupload': 'Upload Again',
  'action.like': 'Like',
  'action.unlike': 'Unlike',
  'action.follow': 'Follow',
  'action.unfollow': 'Unfollow',
  'action.show_latests': 'Show latests',
  'action.show_more': 'Show more',
  'action.view_all': 'View all',
  'action.visit_profile': 'Visit Profile',
  'action.edit_profile': 'Edit Profile',
  'action.contact': 'Contact',
  'action.resend_verification_email': 'Resend Verification Email',
  'action.register_address': 'Register Address',
  'action.make_primary_address': 'Make Primary Address',
  'action.reset_password': 'Reset Password',
  'action.submit_application': 'Submit Application',
  'action.withdraw_application': 'Withdraw Application',
  'action.open_pro_account': 'Open Pro Account',
  'action.start_business_account_application': 'Start Pro Account Application',
  'action.cancel_business_account_application': 'Cancel Pro Account Application',
  'action.spam_report': 'Report',
  'action.order_and_pay': 'Order and Pay',
  'action.complete_order': 'Complete this order',
  'action.accept_order_completion': 'Review and Approve',
  'action.review_and_pay': 'Review and Pay',
  'action.quotation_delete': 'Delete Draft Quotation',
  'action.estimate_delete': 'Delete Draft Estimate',
  'action.show_details': 'Show Details',
  'action.print_quotation': 'Print Quotation / Invoice',
  'action.goto_the_message_for_order': 'To the message for this order',
  'action.show_quotaiton_details': 'Show Deteils',
  'action.show_estimate_details': 'Show Details',
  'action.review_and_pay_quotation': 'Review and Pay',
  'action.edit_business_profile': 'Edit Business Information',
  'action.enter_jp_act_on_specified_commercial_transaction': 'Enter JP act on Specified Commercial Transaction',
  'action.edit_jp_act_on_specified_commercial_transaction': 'Edit JP act on Specified Commercial Transaction',
  'action.link_external_account': 'Link',
  // for Pro Account Consultation
  'action.ask': 'Consult on Your Request',
  'action.check_my_ask_page': 'View My Consultation Page',
  'action.pause_accepting': 'Not Accepting Requests',
  'action.submit_repair_request': 'Send Repair Consultation',
  'action.open_message_inspector': 'Open message inspector',
  'action.next_image': 'Next Image',
  'action.previous_image': 'Previous Image',

  /**
   * MARK: Enum
   * Naming rule: enum.{model}.{attribute}.{value}
   */
  enum: {
    delivery_days_unit: {
      day: 'Day',
      week: 'Week',
      month: 'Month',
    },
    procedure: {
      shipping: 'Shipping',
      in_store_drop_off: 'In store drop off',
      on_site_pickup: 'On site pickup',
      on_site_repair: 'On site repair',
      undefined: 'None Selected',
    },
    'business.status': {
      // reference Business['status']
      draft: 'Awaiting Your Input',
      in_review: 'In Review',
      verified: 'Approved',
      open: 'Open',
    },
    'jp_act_on_specified_commercial_transaction.address_hidden': {
      no: 'Display your address',
      yes: 'Hide your address',
    },
    'jp_act_on_specified_commercial_transaction.phone_hidden': {
      no: 'Display your phone number',
      yes: 'Hide your phone number',
    },
  },

  /**
   * MARK: unit
   */
  unit: {
    separator: ' ',
    works: 'Work |||| Works',
    delivery_days: {
      day: 'Day |||| Days',
      week: 'Week |||| Weeks',
      month: 'Month |||| Months',
    },
    like_count: 'Like! |||| Likes!',
  },

  /**
   * MARK: link @deprecated
   */
  'link.contact': 'Contact',
  'link.back_to_home': 'Back to Home',
  'link.about_us': 'About NAGAKU Inc.',
  'link.privacy_policy': 'Privacy Policy',
  'link.terms_of_service': 'Terms of Service',
  'link.careers': 'Careers',
  'link.japanese_act_on_specified_transactions': '特定商取引法に基づく表記',

  /**
   * MARK: guide @deprecated
   */
  'guide.no_works_yet': 'No Works Yet',
  'guide.page_not_found': 'Page Not Found',
  'guide.hello': 'Hello, {{name}}!',
  'guide.or': 'Or',
  'guide.post_a_work': 'Post a work of your repairs and remakes!',
  'guide.work_estimate_amount': 'Estimated cost for requesting this repair or remake.',
  'guide.contact': 'We are currently only accepting inquiries via email.',
  'guide.this_page_is_for_seinding_inquiries_to_your_account':
    'This page is for sending inquiries to your account. You may copy the URL and share it with others.',
  'guide.have_an_account_already': 'Have an account already?',
  'guide.forget_password': 'Forget your password?',
  'guide.has_been_uploaded': 'Has been uploaded',
  'guide.has_uploading_error': 'Uploading Error',
  'guide.please_verify_email': 'Verification of the email address is required in order to request a repair.',
  'guide.please_verify_email_to_send_repair_request': 'You must verify your email address before requesting a repair.',
  'guide.reset_password': 'Enter your new password to complete the password reset.',
  'guide.recover_password': 'Please enter email address of your account. A password reset email will be sent.',
  'guide.recover_password_email': 'If email address entered is correct, password reset email will be sent.',
  'guide.support_png_or_jpeg': 'Accept only PNG or JPEG',
  'guide.verify_new_email':
    'After updating, a verification email will be sent to your new address. Please follow the link provided to complete the verification.',
  'guide.jp_trn_number_is_not_provided':
    'Japan Invoice Registration Number is currently not entered. This is optional.',
  'guide.business': {
    description: 'Provide a 2-3 sentence description of your business model and products or services.',
    company_representative:
      'As the representative, please enter the information personally, provided by the individual themselves, for one company director.',
    need_to_verify_email_to_open: 'You need to verify your email address to open a Pro Account.',
    need_to_verify_email_to_submit: 'You need to verify your email address to submit a Pro Account application.',
    verified:
      "Your application has been approved. Let's open your Pro account by completing your profile and business profile.",
    open: 'プロアカウントの開設は準備中です。正式リリースをお待ち下さい。',
  },
  'guide.business_draft': 'Please fill out the form and submit your application.',
  'guide.business_privacy_policy':
    'The information you provide will be shared with Stripe (payment service) for the purpose of review. Please refer to the Privacy Policy for details.',
  'guide.business_is_in_review':
    'Your application is currently under review, and the process is usually completed within 5 business days. If any information is missing or incomplete, we will notify you by email.',
  'guide.privacy_policy_translation': 'Privacy Policy is available in Japanese only.',
  'guide.terms_of_service_translation': 'Terms of Service is available in Japanese only.',
  'guide.only_ja': 'This page is available in Japanese only.',
  'guide.unauthorized': 'It seems that you are not logged in.',
  'guide.print_annotation': 'This page is for printing. Please print and use it.',
  'email_verification.instruction': 'Please click the button below to complete your email verification.',
  'email_verification.status.completed': {
    title: 'Email Verification Successful!',
    message: 'Thank you for your cooperation. Your email has been verified.',
    action: 'Return to Home',
  },
  'email_verification.status.expired': {
    title: 'Email Verification Failed',
    message: 'The URL may have expired or has already been processed. Verification URLs are valid for 24 hours.',
  },
  'email_verification.status.error': {
    title: 'Email Verification Failed',
    message: 'The URL may be invalid or there might be a service issue.',
  },

  'guide.navigate_customer_support':
    'If this issue persists, it could be due to a temporary problem with the service. Please try again later or reach out to our support team for assistance.',
  'guide.payment': {
    complete: 'Payment has been completed and the order has been confirmed.',
  },
  'guide.pro_account_profile': 'Cover photo and tagline will become visible once your pro account is open.',
  'guide.edit_business_profile_page':
    'The information you enter here will be displayed in the "About" tab of your profile.',
  'guide.quotation': {
    already_paid: 'Payment has already been completed.',
    already_expired: 'The expiration date has passed.',
  },
  'guide.jp_act_on_specified_commercial_transaction_for_pro_account':
    'If you provide repair services or similar through the internet, you are required to display information in accordance with the Japanese Act on Specified Commercial Transactions.',
  'guide.japanese_act_on_specified_transactions_translation':
    "'特定商取引法に基づく表記' is a display required by Japanese law. It's in Japanese only.",
  'guide.inquiry': {
    domain_filter:
      'To receive emails from NAGAKU, please set up domain-based email filtering to allow "nagaku.com" and "{{domain}}".',
    complete:
      'Your inquiry has been sent to {{display_name}}. You will receive a reply directly to the email address you provided.',
  },
  'guide.about_business_director': 'The business director is not a representative of the company',

  /**
   * MARK: message @deprecated
   */
  'message.success': 'Success!',
  'message.spam_report_success': 'Report accepted',
  'message.successfully_logged_in': 'Login successful. Welcome back!',
  'message.successfully_open_pro_account': 'Your Pro account has been successfully activated!',

  /**
   * MARK: confirmation @deprecated
   */
  confirmation: {
    'work.delete': {
      title: 'Confirm to Delete',
      description: 'Deleted posts cannot be recovered. Are you sure you want to delete?',
      cancel: 'Cancel',
      confirm: 'Confirm Deletion',
    },
    'profile.discard': {
      title: 'You have unsaved changes',
      description: '',
      cancel: 'Continue Editing',
      confirm: 'Discard Changes and Return',
    },
    'business.cancel_application': {
      title: 'Confirm Cancellation of Application',
      description: 'Canceled Pro Account application cannot be recovered. Are you sure you want to cancel?',
      cancel: 'Continue with Application',
      confirm: 'Confirm Cancellation',
    },
    'business.submit_application': {
      title: 'Submit Business Account Application',
      description:
        'Once you submit your application, you will not be able to make changes during the review process. Please ensure that all information is correct before submitting.',
      cancel: 'Back to Input',
      confirm: 'Submit Application',
    },
    'account_setting.discard': {
      title: 'Discard changes?',
      description: 'If you choose to discard, your input will be lost. Are you sure you want to close?',
      cancel: 'Continue Editing',
      confirm: 'Discard',
    },
  },

  // MARK: Misc, requires refactoring.

  inquiry_subject: 'Contact NAGAKU',

  'valication_rule.dob': 'Invalid date',

  'validation.required': '{{name}} is required',
  'validation.min_length': '{{name}} must be at least {{length}} characters long',
  'validation.max_length': '{{name}} must be shorter than {{length}} characters',
  'validation.array_max_length': '{{name}} must have fewer than {{length}} items',
  'validation.include_numeric_character': '{{name}} must include a numeric character',
  'validation.include_symbolic_character': '{{name}} must include a symbolic character',
  'validation.include_alphabetic_character': '{{name}} must include a alphabetic character',
  'validation.meets_password_format': '{{name}} contains invalid characters',
  'validation.invalid_password': '{{name}} is invalid password',
  'validation.confirmation_mismatch': '{{name}} is not match',
  'validation.pattern': '{{name}} is invalid',
  'validation.invalidDate': '{{name}} is invalid date',
  'validation.18_or_more_age': 'Available only to those 18 years of age or older',
  'validation.invalid_postalcode': 'Invalid postal code',
  'validation.kana': '{{name}} can only contain Hiragana and Katakana characters.',
  'validation.address_line_kana':
    '{{name}} can only contain Hiragana, Katakana, numbers, alphabets, and certain symbols.',
  'validation.unknown_postalcode': 'Unknown postal code in Japan',
  'validation.unknown_bank_name': 'Unknown or unregistered bank name, please try other',
  'validation.range': '{{name}} must be a value in {{min}}~{{max}}',
  'validation.amount': "{{name}} can only numeric  or ',' characters.",
  'validation.delivery_days': '{{name}} can only numeric characters.',
  'validation.positive_number': '{{name}} can only be a number greater than 0.',
  'validation.only_integer': '{{name}} can only accept integers.',
  'validation.mime_type': 'Unsupported file format. Supported file formats are {{name}}.',

  control: {
    required: 'Required',
    optional: 'Optional',
    auto_filled: 'Auto-filled',
  },

  status: {
    payment: {
      successful: 'Payment Successful',
      confirmed: 'Payment Confirmed - {{payer}}',
    },
    ticket: {
      complete: 'Repair has been completed',
      accept: 'Item received',
    },
    work: {
      draft: 'Draft',
      published: 'Published',
    },
    account: {
      following: 'Following',
    },
  },

  'form.business': {
    overview: 'Business Overview',
    company_overview: 'Company',
    company_address: 'Business address',
    representative: 'Representative',
    representative_address: 'Representative Home Address',
    id_documents: 'Person ID Documents',
    contact: 'Representative Contact',
    bank_account: 'Bank Account',
  },

  'form.business_profile': {
    address: 'Address',
    hide_details: 'Hide details',
    show_details: 'Show details',
    accepting: 'Accepting',
    paused: 'Paused',
  },

  'form.date': {
    dob: 'Date of birth',
    year: 'Year',
    month: 'Month',
    day: 'Day',
    dob_and_age: 'Born {{day}}/{{month}}/{{year}} - Age {{age}}',
  },

  empty: {
    general: {
      title: 'No Content Found',
      guide: 'No content was found.',
    },
    tag: {
      title: 'No Content Found',
      guide: 'No content was found with this tag. Try selecting a different tag.',
    },
    order: {
      title: 'No Orders Found',
      guide: 'You will see the history of the repairs and remakes you have requested.',
    },
    dashboard_order: {
      title: 'No Orders Found',
      guide: 'You will see the list of the repairs and remakes you have received.',
    },
    message: {
      title: 'No Messages Found',
      guide: 'You will see the list of the messages you have received.',
    },
    like: {
      title: 'No Likes Found',
      guide: 'Find your favorite content and like it.',
    },
  },

  values: {
    business: {
      business_type: {
        individual: 'Individual',
        company: 'Company',
      },
    },
  },

  // MARK: old type phrases

  BeforeAfterSlider: {
    BlankImage: 'Blank Image',
    BeforeImage: 'Before Image',
    AfterImage: 'After Image',
    Handle: 'Visual Slider',
  },

  PermissionMissingView: {
    title: 'Permission Missing',
    message: 'You do not have permission to view this page. (Permission Error)',
  },

  OrderMessageView: {
    message: {
      for_non_caller: `{{caller_display_name}}'s payment has been completed, and this request is now confirmed. Please begin the repair process. Once the repair is completed and shipped, press the 'Complete this order' button.`,
      for_caller:
        'Your payment has been completed, and your request is now confirmed. Please wait until the repair is completed.',
    },
  },

  StartOrderCompletionMessageView: {
    message: {
      for_non_caller: 'A notification has been sent to {{caller_display_name}} regarding the completion of the order.',
      for_caller:
        'You have received a notification from {{repairer_display_name}} regarding the completion of the order. Please check the item and approve the order completion if the work is satisfactory.',
    },
    guide: 'If no action is taken, the order will be automatically completed on {{expires_at}}.',
    accept: {
      title: 'Complete the Order',
      body: "Once you have received the item from {{repairer_display_name}} and confirmed its condition, please press the 'Complete the Order' button.",
    },
  },

  AcceptOrderCompletionMessageView: {
    message: {
      for_non_caller: '{{caller_display_name}} has completed the order.',
      for_caller: 'You have completed the order.',
    },
  },

  WorkCard: {
    ImageCountLabel: 'This post has {{count}} photos',
  },

  NoPasswordMessage: {
    notice: 'Not set',
    title: 'Set a password',
    description:
      'Currently, the password for email login is not set. To protect your account, we recommend setting a password.',
    action: 'Set a password',
  },

  ExternalAccountList: {
    title: 'Linked Accounts',
    name: {
      google: 'Google',
    },
    linked: 'Linked',
  },

  'Message is already closed': 'Message is already closed',

  /**
   * MARK: for each Actions.
   *
   * Actions are defined in the `./src/actions` directory.
   * Each action displays the result (success or error) via the <Toast> component.
   * These phrases are used as <Toast> messages.
   *
   * @reference ./src/lib/action.ts
   */

  'Sample Message': 'Sample Message',

  onPublishWork: {
    success: 'Success to publish your Work!',
    error: 'Work publish failed. {{message}}',
  },

  onSaveDraftWork: {
    success: 'Save as Draft',
    error: 'Work save as draft failed. {{message}}',
  },

  onOpenTicket: {
    success: 'The message has been successfully sent.',
    error: 'Failed to request a consultation. {{message}}',
  },

  onCompleteOrder: {
    success: 'The order completion has been successfully notified.',
    error: 'Failed to notify the order completion. {{message}}',
  },

  onAcceptOrderCompletion: {
    success: 'The order completion has been successfully accepted.',
    error: 'Failed to accept the order completion. {{message}}',
  },

  onRegisterAccount: {
    success: 'You have successfully registered your account!',
    error: 'Account registration failed. {{message}}.',
  },

  onOpenBusinessAccount: {
    success: 'Your Pro Account has been successfully opened.',
    error: 'Failed to open the Pro Account. {{message}}',
  },

  onUpdateProfile: {
    success: 'You have successfully update your profile.',
    error: 'Updating profile failed. {{message}}.',
  },

  onUploadBanner: {
    success: 'You have successfully upload your banner image.Click update button and the change will be reflected',
    error: 'Uploading banner image failed. {{message}}.',
  },

  onUploadIcon: {
    success: 'You have successfully upload your icon image.Click update button and the change will be reflected',
    error: 'Uploading icon image failed. {{message}}.',
  },

  onBecomePrimaryAddress: {
    success: 'Primary address has been changed.',
    error: 'Failed to change the primary address. {{message}}',
  },

  onSubmitBusinessApplication: {
    success: 'You have successfully submitted your Pro Account application!',
    error: 'Submission of the application failed. {{message}}.',
  },

  onWithdrawBusinessApplication: {
    success: 'You have successfully withdrawn your Pro Account application.',
    error: 'Withdrawal of the Pro Account application failed. {{message}}.',
  },

  onUpdateJpActOnSpecifiedCommercialTransaction: {
    success: 'You have successfully updated your japanese specified commercial transaction!',
    error: 'Japanese specified commercial transaction update failed. {{message}}.',
  },

  onUpdateEmail: {
    success:
      'A confirmation email has been sent to your new email address. The change will be completed once the email address is confirmed.',
    error: 'Updating email address failed. {{message}}.',
  },

  onUpdatePassword: {
    success: 'You have successfully updated your password.',
    error: 'Password update failed. {{message}}.',
  },

  onKickRecoveryPassword: {
    success:
      'A password recovery email has been sent. If the email address you entered is correct, you will receive a password recovery email.',
    error: 'Sending password recovery email failed. {{message}}.',
  },

  onRegisterAddress: {
    success: 'You have successfully registered the address.',
    error: 'Adress registration failed. {{message}}.',
  },

  onDeleteAddress: {
    success: 'You have successfully deleted the address.',
    error: 'Address deletion failed. {{message}}.',
  },

  onUpdateBusinessProfile: {
    success: 'You have successfully updated your business profile.',
    error: 'Updating business profile failed. {{message}}.',
  },

  onUpdateReception: {
    success: 'You have successfully updated your reception page.',
    error: 'Updating reception page failed.{{message}}',
  },

  onUpdateBusinessBankAccount: {
    success: 'Saved',
    error: 'Saving failed. {{message}}.',
  },

  onUpdateBusinessCompanyAddress: {
    success: 'Saved',
    error: 'Saving failed. {{message}}.',
  },

  onUpdateBusinessCompany: {
    success: 'Saved',
    error: 'Saving failed. {{message}}.',
  },

  onUpdateBusinessOverview: {
    success: 'Saved',
    error: 'Saving failed. {{message}}.',
  },

  onUpdateBusinessRepresentative: {
    success: 'Saved',
    error: 'Saving failed. {{message}}.',
  },

  onUpdateBusinessRepresentativeAddress: {
    success: 'Saved',
    error: 'Saving failed. {{message}}.',
  },

  onUpdateBusinessRepresentativeContact: {
    success: 'Saved',
    error: 'Saving failed. {{message}}.',
  },

  onUpdateQuotation: {
    success: 'You have successfully updated your quotation.',
    error: 'Updating quotation failed. {{message}}.',
  },

  onUpdateEstimate: {
    success: 'You have successfully updated your estimate.',
    error: 'Updating estimate failed. {{message}}.',
  },

  onPayQuotation: {
    success: 'Payment will be started (Redirect to Stripe)',
    error: 'Failed to start the payment process. {{message}}',
  },

  onUpdateWork: {
    success: 'You have successfully updated your work.',
    error: 'Updating work failed. {{message}}.',
  },

  onLogin: {
    success: 'Successfully logged in.',
    error: 'Login failed. {{message}}',
  },

  onUploadImage: {
    success: 'You have successfully upload image.',
    error: 'Failed to upload image. {{message}}',
  },

  onComment: {
    success: 'You have successfully posted your comment.',
    error: 'Posting comment failed. {{message}}.',
  },

  onCopyWorkUrl: {
    success: 'URL copied to clipboard',
    error: 'Failed to copy URL. {{message}}',
  },

  onPostInquiry: {
    success: 'Inquiry has been successfully sent.',
    error: 'Failed to send the inquiry. {{message}}',
  },
};

export default { phrases } as PolyglotOptions;
