import { FC } from 'react';
import { useSearchParams } from 'react-router';
import { ControlChip } from 'src/components/styles/ControlChip';
import { useI18n } from 'src/lib/i18n';

export const WorkFilterControl: FC = () => {
  const { i18n } = useI18n();
  const [searchParams, setSearchParams] = useSearchParams();
  const isActive = searchParams.get('only') == 'pro';

  const onTogglePro = () => {
    setSearchParams(
      (searchParams) => {
        const prevValue = searchParams.get('only');
        const newSearchParams = new URLSearchParams(searchParams);
        if (prevValue == 'pro') newSearchParams.delete('only');
        else newSearchParams.set('only', 'pro');
        return newSearchParams;
      },
      { replace: true },
    );
  };

  return (
    <section>
      <button className={ControlChip.class} {...ControlChip.build({ toggle: isActive })} onClick={onTogglePro}>
        {i18n.t('label.filter_by_pro')}
      </button>
    </section>
  );
};
