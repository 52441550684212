import { createContext, FC, ReactNode, useState } from 'react';
import { Card } from 'src/components/styles/Card';

export const EditableSectionContext = createContext<{
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
}>({
  isEditing: false,
  setIsEditing: () => {},
});

export const EditableSection: FC<{ preview: ReactNode; edit: ReactNode }> = ({ preview, edit }) => {
  const [isEditing, setIsEditing] = useState(false);
  return (
    <EditableSectionContext value={{ isEditing, setIsEditing }}>
      <section className={Card.class} {...Card.build({ color: 'gray' })}>
        {isEditing ? edit : preview}
      </section>
    </EditableSectionContext>
  );
};
