import './ControlChip.css';

export const ControlChip = {
  class: 'styled-control-chip',
  build: ({ toggle }: { toggle?: boolean }) => {
    const props: Record<string, string> = {};
    props['data-control-chip-toggle'] = toggle ? 'true' : 'false';
    return props;
  },
};
