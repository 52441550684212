import { createContext, useContext, useEffect, useState } from 'react';
import { LoaderFunction, Params } from 'react-router';
import { ApiError } from 'src/lib/errors';
import { useSessionCache } from 'src/lib/storage';

const dispatch = async (
  method: 'GET' | 'POST' | 'PUT' | 'DELETE',
  url: string,
  _headers: { [name: string]: string },
  body: BodyInit | undefined = undefined,
) => {
  const headers: { [name: string]: string } = {
    ..._headers,
    Accept: 'application/json',
  };
  if (method != 'GET' && body) {
    headers['Content-Type'] = 'application/json';
  }
  const response = await fetch(url, {
    credentials: 'include',
    method,
    headers,
    body,
  });
  // 204 No Content
  if (response.status == 204) {
    return { data: null, response };
  }
  if (response.headers.get('Content-Length') == '0') {
    return { data: null, response };
  }
  if (!response.headers.get('Content-Type')?.startsWith('application/json')) {
    throw 'invalid content type';
  }
  const data = await response.json();
  if (response.ok) {
    return { data, response };
  } else {
    throw new ApiError(response, data);
  }
};

const DEFAULT_DISPATCH_OPTIONS = {
  maxRetryCount: 3,
};

export type ApiStatus = {
  auth: 'initializing' | 'unauthenticated' | 'authenticated';
};

export type Combine<T> = T extends [infer U, ...infer V] ? U & Combine<V> : unknown;

export interface IApiClient {
  fetch<R>(path: string, params?: { [name: string]: string }): Promise<R>;
  optionalFetch<R>(path: string, params?: { [name: string]: string }): Promise<R | undefined>;
  dispatch<T, R>(
    method: 'GET' | 'POST' | 'PUT' | 'DELETE',
    path: string,
    params?: { [name: string]: string } | undefined,
    body?: T,
    options?: { maxRetryCount: number },
  ): Promise<R>;
  combine<T extends any[]>(
    ...promises: { [K in keyof T]: Promise<T[K]> }
  ): Promise<T extends [infer U, ...infer V] ? U & Combine<V> : unknown>;
}

export type AccessTokenRefreshStrategy = 'raise_error' | 'refresh';

export class ApiClient extends EventTarget implements IApiClient {
  status: ApiStatus = { auth: 'initializing' };
  apiHeaders: { [name: string]: string };
  apiEndpoint: string;
  accessTokenRefreshStrategy: AccessTokenRefreshStrategy;
  cookie: string | undefined = undefined;

  constructor(options: {
    apiHeaders: { [name: string]: string };
    endpoint: string;
    accessTokenRefreshStrategy?: AccessTokenRefreshStrategy;
  }) {
    super();
    this.apiHeaders = options.apiHeaders;
    this.apiEndpoint = options.endpoint;
    this.accessTokenRefreshStrategy = options.accessTokenRefreshStrategy || 'refresh';
    console.log('[API] initialize with endpoint =', options.endpoint);
  }

  /**
   * Combine multiple api fetch.
   *
   * ```
   * import { api } from "lin/api";
   *
   * const data = await api.combine(
   *   api.fetch<{ article: Article }>(`/v1/articles/1`),
   *   api.fetch<{ comments: Array<Comment> }>(`/v1/comments`, { articleId: 1 }),
   * );
   *
   * console.log(data.article);
   * console.log(data.comments);
   * ```
   */

  async combine<T extends any[]>(
    ...promises: { [K in keyof T]: Promise<T[K]> }
  ): Promise<T extends [infer U, ...infer V] ? U & Combine<V> : unknown> {
    const values = await Promise.all(promises);
    return values.reduce((memo, value) => Object.assign(memo, value), {}) as T extends [infer U, ...infer V]
      ? U & Combine<V>
      : unknown;
  }

  async fetch<R>(path: string, params?: { [name: string]: string }): Promise<R> {
    return await this.dispatch<undefined, R>('GET', path, params);
  }

  async optionalFetch<R>(path: string, params?: { [name: string]: string }): Promise<R | undefined> {
    try {
      return await this.fetch<R>(path, params);
    } catch (error: unknown) {
      if (error instanceof ApiError && error.code == 'resource_not_found') {
        return undefined;
      }
      if (error instanceof ApiError && error.code == 'authentication_required') {
        return undefined;
      }
      throw error;
    }
  }

  get headers() {
    const headers: { [name: string]: string } = Object.assign({}, this.apiHeaders);
    if (this.cookie) {
      headers.Cookie = this.cookie;
    }
    return headers;
  }

  async dispatch<T, R>(
    method: 'GET' | 'POST' | 'PUT' | 'DELETE',
    path: string,
    params: { [name: string]: string } | undefined = undefined,
    body?: T,
    options: { maxRetryCount: number } = DEFAULT_DISPATCH_OPTIONS,
  ): Promise<R> {
    const url = this._buildUrl(path, params || {});
    let lastError: ApiError | undefined = undefined;
    for (let retryCount = 0; retryCount < options.maxRetryCount; retryCount++) {
      try {
        const { data } = await dispatch(method, url.toString(), this.headers, JSON.stringify(body));
        if (import.meta.env.DEV) {
          console.log('[API]', method, path, 'responses successful');
        }
        return data;
      } catch (error: unknown) {
        if (error instanceof ApiError) {
          console.info('[API]', method, path, 'responses api error =', error.code);
          if (error.code == 'invalid_or_expired_token' || error.code == 'authentication_required') {
            if (lastError?.code == 'invalid_or_expired_token') {
              console.info('[API] invalid or expired token error was not resolved');
              throw error;
            }
            if (this.accessTokenRefreshStrategy == 'raise_error') {
              console.info('[API] accessTokenRefreshStrategy = raise_error');
              throw error;
            }
            lastError = error;
            await this.refreshAccessToken();
            if (this.status.auth == 'unauthenticated') {
              console.warn('[API] failed to refresh access token');
              throw error;
            }
            continue;
          }
        }
        throw error;
      }
    }
    if (lastError) throw lastError;
    throw 'invalid_api_request';
  }

  update(status: ApiStatus) {
    if (this.status.auth != status.auth) {
      console.log('[API] update api client status is', status.auth);
      this.status = status;
      // Use update event to refresh DOM.
      this.dispatchEvent(new Event('update'));
    } else {
      this.status = status;
    }
  }

  _authenticationRequest: Promise<ApiStatus | undefined> | undefined;

  async refreshAccessToken() {
    if (this._authenticationRequest) {
      console.log('[API] waiting for refreshing access token');
      return this._authenticationRequest;
    }
    console.log('[API] try to refresh access token');

    // Request new access token and refresh token.
    const url = this._buildUrl('/v1/auth/access_token');
    const promise = dispatch('POST', url.toString(), this.headers)
      .then(({ response: authResponse }) => {
        if (import.meta.env.SSR) {
          // Use responsed cookies as api client cookies for another api request.
          let newCookie = '';
          authResponse.headers.getSetCookie().forEach((cookie) => {
            const entity = cookie.split(';')[0];
            if (entity) {
              newCookie += entity + ';';
            }
          });
          this.cookie = newCookie;
        }
        this.update({ auth: 'authenticated' });
        return this.status;
      })
      .catch((error: unknown) => {
        if (error instanceof ApiError) {
          switch (error.code) {
            case 'invalid_or_expired_token':
            case 'missing_refresh_token':
              console.error(
                `[API] refresh token is invalid or expired, error = ${error.code}, clear old refresh token`,
              );
              this.update({ auth: 'unauthenticated' });
              break;
            default:
              break;
          }
        }
        return undefined;
      });
    this._authenticationRequest = promise;
    await promise;
    this._authenticationRequest = undefined;
  }

  _buildUrl(path: string, params: { [name: string]: string } = {}) {
    return new URL(`${this.apiEndpoint}${path}${new URLSearchParams(params).toString().replace(/^(.+)/, '?$1')}`);
  }

  // Create new ApiClient instance with inherit headers and cookie from request.
  // This method is only used in SSR process.
  // see: ./web/src/entrypoint-server.tsx
  withRequest(request: Request): ApiClient {
    if (import.meta.env.SSR) {
      const apiHeaders = Object.assign({}, this.apiHeaders);

      // Inherit headers from request.
      [
        // https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/X-Forwarded-For
        'X-Forwarded-For',
        // https://api.rubyonrails.org/classes/ActionDispatch/RequestId.html
        'X-Request-Id',
        'User-Agent',
        'Referer',
        'Accept-Language',
      ].forEach((header) => {
        const value = request.headers.get(header);
        if (value) apiHeaders[header] = value;
      });

      const chainedApiClient = new ApiClient({
        apiHeaders,
        endpoint: this.apiEndpoint,
        accessTokenRefreshStrategy: this.accessTokenRefreshStrategy,
      });

      // Inherit cookie from request.
      const cookie = request.headers.get('Cookie') || undefined;
      if (cookie) {
        chainedApiClient.cookie = cookie;
      }

      return chainedApiClient;
    } else {
      return this;
    }
  }
}

const localhost = import.meta.env.SSR ? 'localhost' : window.location.hostname;
const apiEndpoint =
  import.meta.env.SSR || import.meta.env.VITE_REPAIR_API_DIRECT
    ? `${import.meta.env.VITE_REPAIR_API_URL || 'https://api.nagaku.com'}`
    : `${import.meta.env.VITE_REPAIR_WEB_URL || 'https://nagaku.com'}`;

export const api = new ApiClient({
  apiHeaders: {
    'X-Api-Key': import.meta.env.VITE_REPAIR_API_KEY || '',
  },
  endpoint: apiEndpoint.replace('://localhost', `://${localhost}`),
  accessTokenRefreshStrategy: import.meta.env.SSR ? 'raise_error' : 'refresh',
});

export const ApiContext = createContext<IApiClient>(api);

type ApiResponse<T> = {
  data?: T;
  error?: ApiError;
  isLoading: boolean;
  isRefreshing: boolean;
};

export const useApi = <R>(
  path: string,
  params?: { [name: string]: string },
  options: { cache: boolean } = { cache: false },
) => {
  if (import.meta.env.SSR) {
    return { isLoading: true, isRefreshing: false };
  }
  const api = useContext(ApiContext);
  const [cache, setCache] = useSessionCache<R>('api', { path, search: params });
  const [response, setResponse] = useState<ApiResponse<R | undefined>>({
    data: cache,
    isLoading: cache ? false : true,
    isRefreshing: cache ? true : false,
  });
  useEffect(() => {
    api
      .fetch<R>(path, params)
      .then(async (data: R) => {
        if (import.meta.env.DEV) {
          await new Promise((resolve) => setInterval(resolve, 1500));
        }
        setResponse({ data, isLoading: false, isRefreshing: false });
        if (options.cache) setCache(data);
      })
      .catch((error: unknown) => {
        if (error instanceof ApiError) {
          setResponse({ error, isLoading: false, isRefreshing: false });
        } else {
          console.error('Unknown Error on useApi', error);
          setResponse({
            error: new ApiError(new Response(undefined, { status: 500, statusText: 'UnknownError' }), {
              error: 'unknown_error',
              message: 'Unknown Error',
            }),
            isLoading: false,
            isRefreshing: false,
          });
        }
        if (options.cache) setCache(undefined);
      });
  }, [path, JSON.stringify(params), setResponse, options.cache]);
  return response;
};

// Loader Function (react router)

type LoaderContext = {
  api?: ApiClient;
};

export type PageLoader = (args: { request: Request; params: Params; api: IApiClient }) => ReturnType<LoaderFunction>;

export const pageLoaderWrapper: (loader: PageLoader) => LoaderFunction<LoaderContext> = (loader) => {
  return async ({ request, params, context }) => {
    try {
      return await loader({ request, params, api: context?.api || api });
    } catch (error: unknown) {
      if (error instanceof ApiError) {
        throw Response.json(
          { ...error.body, status: error.response.status },
          { status: error.response.status, statusText: error.response.statusText },
        );
      } else {
        throw error;
      }
    }
  };
};
